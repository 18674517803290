import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { v4 } from 'uuid';
import _get from 'lodash/get';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/Container';
import {
  Hero,
  HeroHeading,
  SectionTitle,
  SmallSectionTitle,
  BodyText
} from '../components/Global';
import PricingSlider from '../components/PricingSlider';
import FaqDrop from '../components/FaqDrop';
import Icon from '../components/Icon';
import SimplifyTeamWork from '../components/SimplifyTeamWork';

import theme from '../styles/theme';
import MediaBreakDown from '../styles/MediaBreakDown';
import ButtonSwitcher from '../components/ButtonSwitcher';
import ButtonSwitcherWrapper from '../components/ButtonSwitcher/index.style';


const HeroWrap = styled(Hero)`
  margin-bottom: 24px;

  ${ButtonSwitcherWrapper} {
    margin: 60px 0 16px 0;
  }

  .billed-description {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #262626;
  }
`;

const TableSection = styled.div`
  width: 100%;
  max-width: 1327px;
  margin: auto;
  ${MediaBreakDown.xl`
    display: none;
  `}
`;
const FaqsSection = styled.div`
  width: 100%;
  max-width: 767px;
  margin: 80px auto 0;
  a {
    color: ${theme.hover.hoverBlue};
  }
`;

const PERIOD = {
  ANNUAL: 'annual',
  MONTHLY: 'monthly',
};
const PERIODS = [{
  key: 'annual',
  value: (<span>Annually</span>)
}, {
  key: 'monthly',
  value: (<span>Monthly</span>)
}];

const PRODUCTS = {
  standard: {
    annual: {
      salePrice: 39,
      price: 4.95,
    },
    monthly: {
      salePrice: 47,
      price: 7.95,
    },
  },
  professional: {
    annual: {
      salePrice: 49,
      price: 9.95,
    },
    monthly: {
      salePrice: 59,
      price: 14.95,
    },
  },
};


const PricingPage = ({ data, location }) => {
  const [selectedPeriod, setSelectedPeriod] = React.useState(PERIOD.ANNUAL);

  const handleButtonSwitcherClick = (selectedKey) => {
    setSelectedPeriod(selectedKey);
  };

  return (
    <Layout location={location}>
      <SEO
        title="Pricing Plans | Work Management Platform | Workast - Where Work Gets Done!"
        description="Find out which pricing plan is best for your business. Sign up to Workast to get more work done today!"
      />
      <Container>
        <HeroWrap>
          <HeroHeading mb="10px">A plan for every team</HeroHeading>
          <BodyText>Get started for free. No credit card required.</BodyText>

          <ButtonSwitcher
            items={PERIODS}
            onClick={handleButtonSwitcherClick}
          />
          <span className="billed-description">
            Billed once per
            {' '}
            {selectedPeriod === PERIOD.ANNUAL ? 'year' : 'month'}
          </span>
        </HeroWrap>
        <div className="centered-text">
          <PricingSlider
            products={{
              standard: {
                salePrice: _get(PRODUCTS, `standard.${selectedPeriod}.salePrice`, 0),
                price: _get(PRODUCTS, `standard.${selectedPeriod}.price`, 0),
              },
              professional: {
                salePrice: _get(PRODUCTS, `professional.${selectedPeriod}.salePrice`, 0),
                price: _get(PRODUCTS, `professional.${selectedPeriod}.price`, 0),
              },
            }}
          />
          <TableSection>
            <SectionTitle mb="36px">Compare our plans</SectionTitle>
            <table className="plan-table tablet-hidden">
              <tr>
                <th>Our Plans</th>
                <th>Essential</th>
                <th>Standard</th>
                <th>Pro</th>
                <th>Enterprise</th>
              </tr>
              <tr>
                <td>Tasks</td>
                <td>Unlimited</td>
                <td>Unlimited</td>
                <td>Unlimited</td>
                <td>Unlimited</td>
              </tr>
              <tr>
                <td>Spaces</td>
                <td>Unlimited</td>
                <td>Unlimited</td>
                <td>Unlimited</td>
                <td>Unlimited</td>
              </tr>
              <tr>
                <td>Storage</td>
                <td>100 mb</td>
                <td>
                  <strong>1 GB</strong>
                  / User
                </td>
                <td>
                  <strong>5 GB</strong>
                  / User
                </td>
                <td>
                  <strong>10 GB</strong>
                  / User
                </td>
              </tr>
              <tr>
                <td>Slack integration</td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Webex integration</td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Email integration</td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Custom lists</td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Web and mobile application access</td>
                <td />
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>3rd party integrations (Zapier, Bitbucket, Google Drive)</td>
                <td />
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>API & Webhooks</td>
                <td />
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Custom forms</td>
                <td />
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Notes</td>
                <td />
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Board view</td>
                <td />
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Calendar view</td>
                <td />
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Advanced search and reporting</td>
                <td />
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Export to CSV</td>
                <td />
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>2-way calendar sync</td>
                <td />
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Guest users</td>
                <td />
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Subtask</td>
                <td />
                <td>
                  10 uses
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Custom tags</td>
                <td />
                <td>
                  10 uses
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Templates</td>
                <td />
                <td>
                  10 uses
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Time tracking</td>
                <td />
                <td>
                  10 uses
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Task dependencies</td>
                <td />
                <td>
                  10 uses
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Custom fields</td>
                <td />
                <td>
                  1 field
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Timeline view</td>
                <td />
                <td />
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Support for Slack Grid</td>
                <td />
                <td />
                <td />
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Pay for multiple teams</td>
                <td />
                <td />
                <td />
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Activity logs</td>
                <td />
                <td />
                <td />
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Custom security review</td>
                <td />
                <td />
                <td />
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Flexible pricing</td>
                <td />
                <td />
                <td />
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
              <tr>
                <td>Account manager</td>
                <td />
                <td />
                <td />
                <td>
                  <Icon name="checkIcon" color={theme.icon.blueIcon} />
                </td>
              </tr>
            </table>
          </TableSection>
          <FaqsSection>
            <SmallSectionTitle mb="65px">Frequently Asked Questions</SmallSectionTitle>
            {
              data.allContentfulFaq.edges.map(item => (
                <FaqDrop
                  key={v4()}
                  question={_get(item, 'node.question', '')}
                  answer={_get(item, 'node.answer.childMarkdownRemark.html', '')}
                />
              ))
            }
            <Link to="/help/">Go to help center</Link>
          </FaqsSection>
          <div className="section">
            <SimplifyTeamWork />
          </div>
        </div>
      </Container>
    </Layout>
  );
};

PricingPage.propTypes = {
  data: PropTypes.shape({
    allContentfulFaq: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            question: PropTypes.string.isRequired,
            answer: PropTypes.shape({}).isRequired,
          }).isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
  }).isRequired,
};

export default PricingPage;

export const pageQuery = graphql`
  query PricingPageQuery {
    allContentfulFaq(sort: {order: ASC, fields: order}) {
      edges {
        node {
          question
          answer {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
