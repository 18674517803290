/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React, {
  PureComponent,
} from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Styles
import ButtonSwitcherWrapper from './index.style';


class ButtonSwitcher extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedKey: props.items[0].key,
    };
  }

  handleClick = (e) => {
    const { onClick } = this.props;
    const {
      currentTarget: {
        dataset: {
          key,
        },
      },
    } = e;

    this.setState({
      selectedKey: key,
    });
    onClick(key);
  };

  getElements = () => {
    const {
      items,
    } = this.props;
    const {
      selectedKey,
    } = this.state;

    return items.map((item, index) => {
      let cssName;

      if (selectedKey === item.key) {
        cssName = 'selected';
      }
      else if (index === 0) {
        cssName = 'first-item';
      }
      else if (index === (items.length - 1)) {
        cssName = 'last-item';
      }

      return (
        <button
          type="button"
          key={item.key}
          data-key={item.key}
          className={cssName}
          onClick={this.handleClick}
        >
          {item.value}
        </button>
      );
    });
  };

  render() {
    const itemElements = this.getElements();
    return (
      <ButtonSwitcherWrapper>
        {itemElements}
      </ButtonSwitcherWrapper>
    );
  }
}

/*------------------------------------------------*/
/* PROPTYPES
/*------------------------------------------------*/
ButtonSwitcher.defaultProps = {
  onClick: _noop,
};
ButtonSwitcher.propTypes = {
  // Required props:
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.node.isRequired,
    })
  ).isRequired,
  // Optional props:
  onClick: PropTypes.func,
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default ButtonSwitcher;
