import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from '../Icon';
import theme from '../../styles/theme';

const Wrap = styled.div`
  margin-bottom: 34px;
  width: 100%;
  text-align: left;

  .question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    line-height: 16.8px;
    font-weight: 700;
    color: ${theme.textColor.blackText};
    text-transform: uppercase;

    :hover {
      cursor: pointer;
    }

    &.disabled {
      :hover {
        cursor: initial;
      } 
    }
  }
  .question {
    svg {
      display: block;
      width: 20px;
      height: 8px;
      margin-left: 10px;
      transform: ${props => (props.isVisible ? 'rotate(180deg)' : 'none')};
    }
  }
`;

const Answer = styled.div`
  margin-top: 11px;
  display: block;
  h3, h4, h5 {
    font-size: 16px;
  }
  p, li {
    font-size: 1rem;
    line-height: 1.2rem;
  }
  display: ${props => (props.isVisible ? 'block' : 'none')};
`;

const FaqDrop = ({ question, answer, disableToggle }) => {
  const [isVisible, setVisible] = useState(false);

  let onClickMethod = null;
  let cssQuestion = 'question';

  if (!disableToggle) {
    onClickMethod = () => setVisible(!isVisible);
  }
  else {
    if (!isVisible) {
      setVisible(true);
    }
    cssQuestion += ' disabled';
  }


  return (
    <Wrap isVisible={isVisible}>
      <div
        className={cssQuestion}
        onClick={onClickMethod}
        aria-hidden="true"
      >
        {question}
        {!disableToggle && (
          <Icon name="dropArrow" color={theme.textColor.blackText} />
        )}
      </div>
      <Answer
        isVisible={isVisible}
        dangerouslySetInnerHTML={{ __html: answer }}
      />
    </Wrap>
  );
};

export default FaqDrop;

FaqDrop.defaultProps = {
  disableToggle: false,
};
FaqDrop.propTypes = {
  // Required props:
  question: PropTypes.string.isRequired,
  answer: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  // Optional props:
  disableToggle: PropTypes.bool,
};
