/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import styled from 'styled-components';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/


const ButtonSwitcherWrapper = styled.div`
  button {
    color: #424859;
    cursor: pointer;
    border: 1px solid #424859;
    border-left: none;
    background: none;
    padding: 6px 30px;
    outline: none;

    &.first-item {
      border: 1px solid #424859;
      border-radius: 6px 0 0 6px;
    }
    &.last-item {
      border-radius: 0 6px 6px 0;
    }
    &.selected {
      border: 1px solid #424859;
      border-radius: 6px;
      background: #424859;
      color: #FFFFFF;
      transform: scale(1.1);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      transition: all .15s ease-in;
    }
  }
`;
ButtonSwitcherWrapper.displayName = 'ButtonSwitcherWrapper';


export default ButtonSwitcherWrapper;
