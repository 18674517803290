import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { Link } from 'gatsby';
import theme from '../../styles/theme';
import { BodyText } from '../Global';
import Icon from '../Icon';
import EssentialIcon from './essential.svg';
import StandardIcon from './standard.svg';
import ProfessionalIcon from './professional.svg';
import EnterpriseIcon from './enterprise.svg';
import constants from '../../constants';


const SliderWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 60px;
`;
const PricingCard = styled.div`
  position: relative;
  z-index: 1;
  margin-top: 15px;
  width: calc(100% - 20px) !important;
  height: 437px;
  padding: 27px 20px 37px;
  border: 1px solid ${theme.borderColor.lightGray};
  border-top: 6px solid ${props => props.borderColor};
  outline: none;
  
  h3 {
    font-size: 24px;
    line-height: 28.8px;
    font-weight: 700;
    color: ${theme.textColor.darkGrayText};
  }

  .main-price-container {
    display: flex;
    justify-content: center;

    .main-price {
      font-weight: 900;
      font-size: 36px;
      line-height: 43px;
      color: #2C3346;
    }
    .currency-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 0 0 6px;
      text-align: initial;

      .currency {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #262626;
      }
      .period {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #424859;
      }
    }
  }
  .annually {
    color: ${theme.textColor.darkGrayText};
  }

  .plan-description-wrapper {
    margin: 20px 0 0 0;

    ${BodyText} {
      margin: 0;
    }
  }
  img {
    margin: auto;
  }
  button {
    position: absolute;
    width: calc(100% - 40px);
    text-align: center;
    left: 20px;
    bottom: 40px;
    font-size: 16px;
    line-height: 19.2px;
    color: ${theme.priceColor.price};
    background: ${theme.body.dark};
    border: 1.5px solid ${theme.borderColor.darkGray}; 
    border-radius: 6px;
    outline: none;
    padding: 8px 0 9px;
    svg {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      margin-bottom: 2px;
    }
    :hover {
      cursor: pointer;
      color: ${theme.body.dark};
      background: ${theme.borderColor.darkGray};
      svg {
        path {
          fill: white;
        }
      }
    }
  }
`;
const ImageWrap = styled.div`
  margin-top: ${props => props.mt};
  margin-bottom: ${props => props.mb};
`;
const Description = styled.div`
  position: absolute;
  bottom: 93px;
  width: calc(100% - 40px);
  font-size: 18px;
  line-height: 21.6px;
  font-weight: 400;
  color: ${theme.textColor.darkGrayText};
`;
const PricingSlider = (props) => {
  const {
    products,
  } = props;
  const settings = {
    infinite: true,
    autoPlay: true,
    slidesToShow: 4,
    speed: 500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          className: 'center',
          centerMode: true,
          centerPadding: '10%',
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerPadding: '10%',
        }
      }
    ]
  };
  return (
    <SliderWrap>
      <Slider {...settings}>
        <PricingCard borderColor={theme.priceColor.essential}>
          <h3>Essential</h3>
          <div className="main-price-container">
            <div className="main-price">$0</div>
            <div className="currency-container">
              <div className="currency">USD</div>
              <div className="period">/month</div>
            </div>
          </div>
          <div className="plan-description-wrapper">
            <BodyText>
              Free forever plan
              <br />
              for unlimited users
            </BodyText>
          </div>
          <ImageWrap mt="12px" mb="11px">
            <img src={EssentialIcon} alt="essential plan" />
          </ImageWrap>
          <Description>Light task management for Slack and Webex.</Description>
          <Link to="/get-started/?ref=pricing-essential">
            <button type="button">Get Started</button>
          </Link>
        </PricingCard>
        <PricingCard borderColor={theme.priceColor.standard}>
          <h3>Standard</h3>
          <div className="main-price-container">
            <div className="main-price">
              $
              {_get(products, 'standard.salePrice', 0)}
            </div>
            <div className="currency-container">
              <div className="currency">USD</div>
              <div className="period">/month</div>
            </div>
          </div>
          <div className="plan-description-wrapper">
            <BodyText className="annually">
              8 users included
            </BodyText>
            <BodyText className="annually">
              $
              {_get(products, 'standard.price', 0)}
              {' '}
              / additional user / mo.
            </BodyText>
          </div>
          <ImageWrap mt="12px" mb="11px">
            <img src={StandardIcon} alt="standard plan" />
          </ImageWrap>
          <Description>Essential plus basic project management features.</Description>
          <Link to="/get-started/?ref=pricing-standard">
            <button type="button">Try for free</button>
          </Link>
        </PricingCard>
        <PricingCard borderColor={theme.priceColor.professional}>
          <h3>Professional</h3>
          <div className="main-price-container">
            <div className="main-price">
              $
              {_get(products, 'professional.salePrice', 0)}
            </div>
            <div className="currency-container">
              <div className="currency">USD</div>
              <div className="period">/month</div>
            </div>
          </div>
          <div className="plan-description-wrapper">
            <BodyText className="annually">
              8 users included
            </BodyText>
            <BodyText className="annually">
              $
              {_get(products, 'professional.price', 0)}
              {' '}
              / additional user / mo.
            </BodyText>
          </div>
          <ImageWrap mt="12px" mb="11px">
            <img src={ProfessionalIcon} alt="professional plan" />
          </ImageWrap>
          <Description> Manage long term projects and advance customization</Description>
          <Link to="/get-started/?ref=pricing-professional">
            <button type="button">Try for free</button>
          </Link>
        </PricingCard>
        <PricingCard borderColor={theme.priceColor.enterprise}>
          <h3>Enterprise</h3>
          <ImageWrap mt="85px" mb="55.21px">
            <img src={EnterpriseIcon} alt="enterprise plan" />
          </ImageWrap>
          <Description>Roll out Workast to your whole organization</Description>
          <a
            href={constants.URLS.CONTACT_SALES}
            target="_blank"
            rel="noreferrer"
          >
            <button type="button">
              <Icon name="contactIcon" color={theme.priceColor.price} />
              Contact sales
            </button>
          </a>
        </PricingCard>
      </Slider>
    </SliderWrap>
  );
};

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
PricingSlider.propTypes = {
  products: PropTypes.shape({
    standard: PropTypes.shape({
      salePrice: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
    }),
    professional: PropTypes.shape({
      salePrice: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
    }),
  }).isRequired,
};
/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default PricingSlider;
